export enum MqttAdapterEnum {
    MINISTER_PID,
    SAFEIT_HUM,
    SMARTVENT,
    SMARTVENT_V2,
    COBRA,
    SOLAR,
    HABA_MULTI_BIO_3000,
    HABA_MULTI_BIO_3100,
    HABA_MULTI_BIO_3100_V2,
    HABA_MULTI_BIO_3200,
    HABA_MULTI_BIO_2000,
    MINISTER_PELET,
    MINISTER_ZG,
    MINISTER_KOLOR_28,
    WIND_TURBINE_CONTROL,
    WIND_TURBINE_CONTROL_GONDOLA,
    MINISTER_ELEKTR,
    MINISTER_WSTER,
    LFP_EPOMP,
    TWEETOP_POMPA,
    MINISTER_MAX,
    TWEETOP_OP,
    TWEETOP_POMPA_V2
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MqttAdapterEnum {
    // eslint-disable-next-line no-inner-declarations
    export function names(): Array<string> {
        const keys = Object.keys(MqttAdapterEnum);
        return keys.slice(keys.length / 2, keys.length - 1);
    }
}
