export enum ProfileEnum {
    'Minister-PID',
    'Minister-ELEKTR',
    'Minister-MAX',
    'SafeIT-HUM',
    'Smartvent',
    'SmartventV2',
    'Haba',
    'Cobra',
    'Solar',
    'HabaMultiBio2000',
    'HabaMultiBio3200',
    'Minister-PELET',
    'Minister-ZG',
    'Minister-Kolor-28',
    'Wind-Turbine-Control',
    'Wind-Turbine-Control-Gondola',
    'Minister-WSTER',
    'LFP-ePomp',
    'Tweetop-pompa',
    'Tweetop-OP',
    'Tweetop-pompa-v2',
    'HabaMultiBio3100v2'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ProfileEnum {
    // eslint-disable-next-line no-inner-declarations
    export function names(): Array<string> {
        const keys = Object.keys(ProfileEnum);
        return keys.slice(keys.length / 2, keys.length - 1);
    }
}
