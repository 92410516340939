import { Injectable } from '@angular/core';
import { DeviceSchema, DeviceSchemaField } from '@balrog/core/model/device';
import { Haba3100v2Profile } from './profiles/Haba3100v2.profile';
import { CobraProfile } from './profiles/Cobra.profile';
import { HabaProfile } from './profiles/Haba.profile';
import { HabaMultiBio2000Profile } from './profiles/HabaMultiBio2000.profile';
import { HabaMultiBio3200Profile } from './profiles/HabaMultiBio3200.profile';
import { BriefPanelConfigInterface } from './profiles/interface/brief-panel-config.Interface';
import { DashboardConfigInterface } from './profiles/interface/dashboard-config.interface';
import { HistoryboardConfigInterface } from './profiles/interface/historyboard-config.Interface';
import { ConfigContentInterface, ListboardConfigInterface } from './profiles/interface/listboard-config.Interface';
import { ConfigContentItemRangeInterface } from './profiles/interface/listboard/config-content-item-range.interface';
import { ConfigContentItemInterface } from './profiles/interface/listboard/config-content-item.interface';
import { ConfigContentMenuInterface } from './profiles/interface/listboard/config-content-menu.interface';
import { ProfileInterface } from './profiles/interface/profile.interface';
import { ScheduleboardConfigInterface } from './profiles/interface/scheduleboard-config.interface';
import { LFPEPompProfile } from './profiles/LFP-ePomp.profile';
import { MinisterElektrProfile } from './profiles/Minister-elektr.profile';
import { MinisterLfpWsterProfile } from './profiles/Minister-lfp-wster.profile';
import { MinisterMaxProfile } from './profiles/Minister-max.profile';
import { MinisterPeletProfile } from './profiles/Minister-pelet.profile';
import { MinisterZgProfile } from './profiles/Minister-zg.profile';
import { WindTurbineControlProfile } from './profiles/Wind-Turbine-Control.profile';

import { MinisterPidProfile } from './profiles/Minister-pid.profile';
import { SafeitHumProfile } from './profiles/Safeit-hum.profile';
import { SmartventProfile } from './profiles/Smartvent.profile';
import { SmartventProfileV2 } from './profiles/Smartventv2.profile';
import { SolarProfile } from './profiles/Solar.profile';
import { TweetopOPProfile } from './profiles/Tweetop-OP.profile';
import { TweetopPompaV2Profile } from './profiles/Tweetop-pompa-v2.profile';
import { TweetopPompaProfile } from './profiles/Tweetop-pompa.profile';
import { WindTurbineControlGondolaProfile } from './profiles/Wind-Turbine-Control_Gondola.profile';
import { MinisterKolor28Profile } from './profiles/Minister-kolor-28.profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  config: {[key: string]: ProfileInterface} = {
    'Minister-PID': new MinisterPidProfile(),
    'Minister-PELET': new MinisterPeletProfile(),
    'Minister-ZG': new MinisterZgProfile(),
    'Wind-Turbine-Control': new WindTurbineControlProfile(),
    'Wind-Turbine-Control-Gondola': new WindTurbineControlGondolaProfile(),
    'Minister-ELEKTR': new MinisterElektrProfile(),
    'Minister-WSTER': new MinisterLfpWsterProfile(),
    'Minister-MAX': new MinisterMaxProfile(),
    'Minister-Kolor-28': new MinisterKolor28Profile(),
    'SafeIT-HUM': new SafeitHumProfile(),
    Smartvent: new SmartventProfile(),
    SmartventV2: new SmartventProfileV2(),
    Haba: new HabaProfile(),
    HabaMultiBio3100v2: new Haba3100v2Profile(),
    HabaMultiBio2000: new HabaMultiBio2000Profile(),
    HabaMultiBio3200: new HabaMultiBio3200Profile(),
    Cobra: new CobraProfile(),
    Solar: new SolarProfile(),
    'LFP-ePomp': new LFPEPompProfile(),
    'Tweetop-pompa': new TweetopPompaProfile(),
    'Tweetop-OP': new TweetopOPProfile(),
    'Tweetop-pompa-v2': new TweetopPompaV2Profile()
  };

  constructor() {
  }

  statuses(schema: DeviceSchema): any {
    return this.getProfile(schema)?.statuses;
  }

  alarms(schema: DeviceSchema): any {
    return this.getProfile(schema)?.alarms;
  }

  dashBoard(schema: DeviceSchema): DashboardConfigInterface | null {
    const board = this.getProfile(schema)?.dashBoard;
    return board ? board : null;
  }

  historyBoard(schema: DeviceSchema): HistoryboardConfigInterface | null {
    const board = this.getProfile(schema)?.historyBoard;
    return board ? board : null;
  }

  scheduleBoard(schema: DeviceSchema): ScheduleboardConfigInterface | null {
    const scheduleBoard = this.getProfile(schema)?.scheduleBoard;
    return scheduleBoard ? scheduleBoard : null;
  }

  listBoard(schema: DeviceSchema, accessLevel: number): ListboardConfigInterface | null {
    const listBoard = this.getProfile(schema)?.listBoard;
    if (listBoard && schema?.fields) {
      this.enrichListBoard(schema.fields, accessLevel, listBoard.content);
    }
    return listBoard ? listBoard : null;
  }

  briefPanel(schema: DeviceSchema): BriefPanelConfigInterface|null {
    const panel = this.getProfile(schema)?.briefPanel;
    return panel ? panel : null;
  }

  private enrichListBoard(schemaFields: {[key: string]: DeviceSchemaField}, accessLevel: number, content: Array<ConfigContentInterface>)
    : Array<ConfigContentInterface> {
    content.forEach((entry) => {
      if (entry.type === 'MENU') {
        const menuEntry = entry as ConfigContentMenuInterface;
        if (!menuEntry.readPermission || accessLevel >= Number(entry.readPermission)) {
          return this.enrichListBoard(schemaFields, accessLevel, menuEntry.content);
        } else {
          menuEntry.hide = true;
          menuEntry.content = [];
        }
      } else if (entry.type === 'ARRAY_RANGE') {
        const rangeEntry = entry as ConfigContentItemRangeInterface;
        if (accessLevel >= Number(schemaFields[rangeEntry.paramIdStart].readPermission) &&
          accessLevel >= Number(schemaFields[rangeEntry.paramIdStop].readPermission)) {
          Object.assign(entry, schemaFields[rangeEntry.paramIdStart]);
          rangeEntry.hide = false;
        } else {
          rangeEntry.hide = true;
        }
      } else {
        const itemEntry = entry as ConfigContentItemInterface;
        if (itemEntry.paramId && schemaFields[itemEntry.paramId]) {
          Object.assign(itemEntry, schemaFields[itemEntry.paramId]);

          const readLevel = this.determineHighestLevel(itemEntry.readPermission, schemaFields[itemEntry.paramId].readPermission);
          if (accessLevel < readLevel) {
            itemEntry.hide = true;
          }

          const writeLevel = this.determineHighestLevel(itemEntry.writePermission, schemaFields[itemEntry.paramId].writePermission);
          if (writeLevel === 0) {
            itemEntry.readonly = true;
          } else if (accessLevel < writeLevel) {
            itemEntry.deny = true;
          }
        } else {
          itemEntry.hide = true;
        }
      }
    });
    return content;
  }

  private getProfile(schema: DeviceSchema): ProfileInterface | null {
    if (schema?.profile && this.config[schema.profile]) {
      return this.config[schema.profile];
    } else {
      console.error('Invalid profile is configured for schema: ' + schema?.name);
      return null;
    }
  }

  private determineHighestLevel(profileLevel: any, schemaLevel: any): number {
    return Math.max(Number(profileLevel || 0), Number(schemaLevel));
  }
}
