import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {ProfileInterface} from './interface/profile.interface';

export class MinisterKolor28Profile implements ProfileInterface {
    statuses = {
        POMPA_CO: { title: 'Pompa CO' },
        POMPA_CWU: { title: 'Pompa CWU' },
        PODAJNIK: { title: 'Podajnik' },
        DMUCHAWA: { title: 'Dmuchawa' },
        TERMOSTAT: { title: 'Termostat' },
        STYK_TERMOSTATU: { title: 'Styk termostatu' },
        GRZALKA: { title: 'Grzałka' },
        PRACA_KOTLA: { title: 'Praca kotła' }
    };

    // alarmy
    alarms = {
        CZUJNIK_CO: {title: 'Czujnik CO'},
        KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
        STB: {title: 'Stb'},
        STB_230V: {title: 'Stb 230V'},
        CZUJNIK_TEMP_SPALIN: {title: 'Czujnik temp. spalin'},
        ROZPALANIE_NIEUDANE: {title: 'Nieudane rozpalanie'},
        CZUJNIK_CWU: {title: 'Czujnik CWU'},
        CO_WARN: {title: 'Tlenek węgla przekroczył poziom ostrzegawczy'},
        CO_ALARM: {title: 'Tlenek węgla przekroczył poziom alarmowy'},
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_MINISTER_PELET
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_MINISTER_ZG
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_PODAJNIKA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_SPALIN',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE,
            type: 'stepped',
            vAxisType: VAxisType.POWER_PERCENTAGE.valueOf()
        }, ]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [
        {
            paramId: 'MKOLOR28_VAL_SOFT_VER',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_CH',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_DHW',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_INLET',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_BUFFER',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_FEEDER',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_VALVE',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_EXHAUST',
        },
        {
            paramId: 'MKOLOR28_VAL_TEMP_EXTERNAL',
        },
        {
            paramId: 'MKOLOR28_VAL_BLOWER_POWER',
        },
        {
            paramId: 'MKOLOR28_VAL_BOILER_POWER',
        },
        {
            paramId: 'MKOLOR28_VAL_WORK_STATE',
        },
        {
            paramId: 'MKOLOR28_VAL_CURRENT_SETPOINT_CH',
        },
        {
            paramId: 'MKOLOR28_VAL_CURRENT_SETPOINT_DHW',
        },
        {
            title: 'Parametry pracy',
            type: 'MENU',
            content: [{
                paramId: 'MKOLOR28_SETP_SETPOINT_BOILER_TEMP',
                step: 0.1,
                unit: Unit.C
            }]
        }, {
            paramId: 'NOW_TIME',
            type: 'ITEM-TEXT',
            placeholder: 'HH:MM'
        }, {
            paramId: 'NOW_DATE',
            type: 'ITEM-TEXT',
            placeholder: 'yyyy-mm-dd'
        }]
    };

    scheduleBoard = null;
}
